const NUM_ROWS = 10;

export default async ({reload, filter, options = {}, dateRequest, fetchData, fetchMoreLoading, data, setData, hasMore, setHasMore, setToast, wrapperId}) => {

    console.log("fetchMoreData start");

    const stopfetchData = () => {
        setHasMore(false);
        fetchMoreLoading = false;
    }

    let hasMoreTmp = hasMore;
    let skip;

    if(reload){
        setHasMore(true);
        hasMoreTmp = true;
        //если не нужно обновлять data на другие значения - обнуляем
        if(!options.data) setData([]);
        setData([]);
        skip = 0;
        dateRequest = new Date();
    }else{
        skip = data.length;
    }

    //если требуется корректировка skip
    if(options.skip) skip = options.skip;

    if (fetchMoreLoading || !hasMoreTmp) {
        //если в любом случае нужно обновить data
        if(options.data) setData(options.data);
        return;
    }

    fetchMoreLoading = true;

    const result = await fetchData({skip, limit: (options.limit || NUM_ROWS), ...filter, dateRequest});

    if (!(result && result.status && result.data ) ) {
        //console.log("Ошибка загрузки данных");
        stopfetchData();
        setToast({open: true, severity: "error", text: result.data && result.data.error || "Неизвестная ошибка загрузки данных!"});
        //если в любом случае нужно обновить data
        if(options.data) setData(options.data);
        return;
    }

    if( !(result.data && result.data.length) ){
        stopfetchData();
        //если в любом случае нужно обновить data
        if(options.data) setData(options.data);
        return;
    }

    if(reload){
        setData(result.data);
    }else{
        setData((options.data || data).concat(result.data));
    }

    //если требуется коррректировка scrollTop;
    if(options.scrollToPosition){
        document.querySelector("#"+wrapperId).scrollTop = options.scrollTop || 0;
    }

    fetchMoreLoading = false;

}